import { validate } from 'email-validator';

export const isValidEmail = (input: string) => {
  if (input.length) {
    if (validate(input)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const validatePassword = (password: string) => {
  return (
    password.length >= 8 && // At least 8 characters
    /[0-9]/.test(password) && // Contains a number
    /[^A-Za-z0-9]/.test(password) && // Contains a symbol
    /[A-Z]/.test(password) && // Contains an uppercase letter
    /[a-z]/.test(password) // Contains a lowercase letter
  );
};
