import BaseRepository from '../../../baseRepository/BaseRepository';
const resource = '/auth';
interface UserInfo {
  access_token: string;
  hourly_rate: number | null;
  name: string;
  role: { id: number; title: string; is_shared: boolean };
  username: string;
}
const authRepository = {
  postLogin(body: { username: string; password: string }) {
    return BaseRepository.post<UserInfo>(`${resource}/login`, body);
  },
  resetPass(body: { email: string}) {
    return BaseRepository.post(`${resource}/forget-pass`, body);
  },
  changePass(body: {email: string; otp: string; password: string; }) {
    return BaseRepository.post(`${resource}/change-pass`, body);
  },
  confirmAccount(body: { username: string; name: string; last_name:string; password: string; confirmPassword: string; token: string;}) {
    return BaseRepository.post(`${resource}/confirm-account`, body);
  },
};
export default authRepository;
